<template>
  <div class="container">
    <!-- First Row: Buttons and Date Pickers -->
    <label class="mb-2">{{ data.title }} (ตัดรอบเที่ยงคืน)</label>
    <div class="first-row">
      <b-button variant="primary" @click="setToday">วันนี้</b-button>
      <b-button variant="primary" @click="setWeek">อาทิตย์นี้</b-button>
      <b-button variant="primary" @click="setMonth">เดือนนี้</b-button>
      <b-form-datepicker
        variant="primary"
        date-format="yyyy-MM-dd"
        id="from"
        v-model="fromDate"
        class="mb-2 mr-1"
      ></b-form-datepicker>
      <b-form-datepicker
        variant="primary"
        id="to"
        v-model="toDate"
        class="mb-2 mr-1"
        date-format="yyyy-MM-dd"
      ></b-form-datepicker>
      <b-button variant="primary" @click="getData">ค้นหา</b-button>
    </div>

    <!-- Second Row: Statistic Cards -->
    <div class="statistics-row" v-show="showContent">
      <statistic-card-horizontal
        icon="EyeIcon"
        :showButton="true"
        :statistic="data.totalMember"
        statistic-title="จำนวนลูกค้าทั้งหมด"
      />
      <statistic-card-horizontal
        icon="EyeIcon"
        :showButton="true"
        :statistic="data.totalNewMember"
        statistic-title="จำนวนที่สมัครใหม่"
      />
      <statistic-card-horizontal
        icon="EyeIcon"
        :showButton="true"
        :statistic="data.newDepositMember"
        statistic-title="จำนวนคนฝาก (ใหม่)"
      />
      <statistic-card-horizontal
        icon="EyeIcon"
        :showButton="true"
        :statistic="data.newMemberNotDeposit"
        statistic-title="คนสมัครใหม่ที่ไม่ฝาก"
      />
      <statistic-card-horizontal
        icon="CpuIcon"
        :showButton="false"
        :statistic="data.depositAmount"
        statistic-title="ยอดฝากรวม"
      />
      <statistic-card-horizontal
        icon="CpuIcon"
        :showButton="false"
        :statistic="data.withdrawAmount"
        statistic-title="ยอดถอนรวม"
      />
      <statistic-card-horizontal
        icon="CpuIcon"
        :showButton="false"
        :statistic="data.bonus"
        statistic-title="โบนัส"
      />
      <statistic-card-horizontal
        icon="CpuIcon"
        :showButton="false"
        :statistic="data.winloss"
        statistic-title="กำไร"
      />

      <!-- <statistic-card-horizontal
        icon="CpuIcon"
        :statistic="data.netWinloss"
        statistic-title="แพ้ชนะ"
      /> -->
      <statistic-card-horizontal
        icon="CpuIcon"
        :showButton="false"
        :statistic="data.oldDepositMember"
        statistic-title="จำนวนคนฝาก (เก่า)"
      />

      <statistic-card-horizontal
        icon="EyeIcon"
        :showButton="true"
        :statistic="data.commission"
        statistic-title="ค่าคอมที่จะได้รับ"
      />
      <statistic-card-horizontal
        icon="CpuIcon"
        :showButton="false"
        :statistic="data.firstDeposit"
        statistic-title="ฝากแรก"
      />
    </div>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Login"
      cancel-title="Close"
      centered
      title="กรุณากรอกรหัสผ่าน"
      v-model="showLoginModal"
      no-footer
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group>
          <label for="password">รหัสผ่าน</label>
          <b-form-input
            v-model="password"
            type="password"
            placeholder="รหัสผ่าน"
          />
        </b-form-group>
      </b-form>
      <b-button variant="primary" class="ml-auto" @click="handleSubmit"
        >ยืนยัน</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import {
  BFormDatepicker,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { getPartnerData, setPartnerData } from '../auth/utils'

const expirationTimeInSeconds = 60

export default {
  components: {
    // BSV
    StatisticCardHorizontal,
    BFormDatepicker,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup
  },
  directives: {
    'b-modal': BModal
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      status: '',
      // validation rules
      required,
      email,
      fromDate: null,
      toDate: null,
      data: null,
      ref: '',
      showLoginModal: false,
      password: '',
      isLoggedIn: null,
      showContent: false
    }
  },
  methods: {
    setToday() {
      const today = new Date()
      this.fromDate = new Date(today)
      this.toDate = new Date(today)
      this.toDate.setDate(this.toDate.getDate() + 1)
    },
    setWeek() {
      const today = new Date()
      const startOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay() + 1)
      )
      const endOfWeek = new Date(startOfWeek)
      endOfWeek.setDate(endOfWeek.getDate() + 7)

      this.fromDate = startOfWeek
      this.toDate = endOfWeek
    },
    setMonth() {
      const today = new Date()
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      this.fromDate = startOfMonth
      this.toDate = endOfMonth
    },
    async getData() {
      const resp = await this.$http.post('partner/dashboard', {
        ref: this.ref,
        from: this.fromDate,
        to: this.toDate
      })
      if (resp.data.success === false) {
        window.location.href = '/'
      }
      this.data = resp.data.data
      console.log('getData', resp)
    },
    async handleSubmit() {
      console.log('AAAAA')

      const resp = await this.$http.post('partner/login', {
        ref: this.ref,
        password: this.password
      })

      if (resp.data.success === true) {
        setPartnerData(this.ref, this.data, expirationTimeInSeconds)
        this.showLoginModal = false
        this.showContent = true
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'รหัสผ่านไม่ถูกต้อง',
            icon: 'EditIcon',
            variant: 'danger'
          }
        })
      }
    }
  },
  async beforeMount() {
    this.setToday()

    if (typeof this.$route.query.ref === 'undefined') {
      window.location.href = '/'
    } else {
      this.ref = this.$route.query.ref
    }

    await this.getData()

    this.isLoggedIn = getPartnerData(this.ref)

    if (this.isLoggedIn === null) {
      this.showLoginModal = true
    } else {
      this.showContent = true
    }

    console.log('isLoggedIn', this.isLoggedIn)
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
#modal-login .modal-footer {
  display: none;
}

#modal-login .modal-header .close {
  display: none;
}

.container {
  padding-top: 40px;

  label {
    font-size: 20px;
  }

  .first-row {
    display: flex;
    margin-bottom: 20px;
    flex: 1;
    justify-content: flex-end;

    button {
      margin-right: 10px; // Spacing between buttons
      height: 40px;
      // Add more button styling here if needed
    }
  }

  .first-row .b-form-datepicker {
    width: 300px; /* or any other fixed width */
  }

  .statistics-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr); // 4 columns layout
    gap: 20px; // Spacing between cards

    statistic-card-horizontal {
      // Styles for statistic cards (if needed)
    }
  }
}
</style>
